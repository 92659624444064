import styles from "./nav.module.css";
import classNames from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";

const links = [
  { href: "/therapy", title: "Therapy" },
  { href: "/classes", title: "Classes" },
  { href: "/groups", title: "Groups" },
  { href: "/events", title: "Events" },
  { href: "/venue", title: "Venue" },
  { href: "/contact", title: "Contact" },
  // {href: "/classes", title: "Classes"},
  // {href: "/events", title: "Events"},
  // {href: "/arts", title: "Arts"},
  // {href: "/blog", title: "Blog"},
  // {href: "/live-streams", title: "Live Streams"}
];

export default function Nav() {
  const path = "/";
  const { pathname } = useLocation();

  return (
    <div className={styles.nav}>
      <ul>
        {links.map((item) => {
          return (
            <li key={item.href + item.title}>
              <Link
                className={classNames({
                  [styles.active]: item.href === pathname,
                })}
                to={item.href}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
  // Mental Health Services
  // - Therapy - a way to add name and email and brief description and we will get back to them
  // - Groups
  // -  Sliding Scale Services

  // Classes
  // - Class descriptions and a way to click and register

  // Upcoming Events
  // - Scrolling events by calendar timeline

  // Arts
  // - Releases from Scribble , in house art exhibits and gallery shows

  // Blog
  // - by date

  // About

  // Donate!
  // - way to donate via paypal, applepay?

  // Live Stream
  // - live link to video stream of any performances etc.

  // Inquiry

  // Get Involved
}
