"use client"; // This is a client component 👈🏽
import { useState } from "react";
import "../globals.css";

export default function Contact() {
  const [successTrigger, setSuccessTrigger] = useState(false);

  const sendContact = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.currentTarget);
      const params = new URLSearchParams(formData as any);

      const buffer = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(
          formData.toString() + process.env.REACT_APP_CONFIG
        )
      );

      const response = await fetch(
        "https://api.scribblecommunity.com/api/standard?" + params
      );
      if (response.ok) {
        setSuccessTrigger(true);
        e.currentTarget.reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Contact</h1>
      <div className="flexGridHalf">
        <div className="col">
          <p>Use the form below to contact us</p>
          <p>* = required</p>

          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              sendContact(e);
            }}
            action=""
          >
            {successTrigger && (
              <div className="success">
                Thanks for reaching out! Your message was sent successfully.
              </div>
            )}

            {!successTrigger && (
              <div className="form">
                <div className="formItem">
                  <div className="flexGridHalf">
                    <div className="col">
                      <label htmlFor="firstName">First name*</label>
                      <br />
                      <input required name="firstName" type="text" />
                    </div>
                    <div className="col">
                      <label htmlFor="lastName">Last name*</label>
                      <br />
                      <input required name="lastName" type="text" />
                    </div>
                  </div>
                </div>

                <div className="formItem">
                  <label htmlFor="pronouns">Pronouns</label>
                  <br />
                  <input name="pronouns" type="text" />
                </div>

                <div className="formItem">
                  <label htmlFor="phone">Phone</label>
                  <br />
                  <input name="phone" type="tel" />
                </div>

                <div className="formItem">
                  <label htmlFor="phone">Email*</label>
                  <br />
                  <input required name="email" type="email" />
                </div>

                <div className="formItem">
                  <label htmlFor="mailto">Subject*</label>
                  <br />
                  <select name="mailto" required defaultValue="info">
                    <option value="therapy">Therapy</option>
                    <option value="volunteer">Volunteering</option>
                    <option value="jake">Web issue</option>
                  </select>
                </div>

                <div className="formItem">
                  <label htmlFor="phone">Message*</label>
                  <br />
                  <textarea required name="message" />
                </div>

                <div className="formItem">
                  <input
                    className="submitEmailButton"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="col">
          <h4 className="font-bold">Location</h4>
          <p>
            5541 York Blvd <br />
            Los Angeles, California 90042
          </p>
          <iframe
            width="100%"
            height="450"
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD7m6It1aLDw5BE5E3asmE6FEdcnhFaskg
                            &q=5541+York+Blvd,+LA+90042"
          ></iframe>
        </div>
      </div>
    </>
  );
}
