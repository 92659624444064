"use client";

import { useEffect, useState } from "react";
import { getPage } from "../sanity/client";
import { PortableText } from "@portabletext/react";
import styles from "./therapy.module.css";
import CustomContent from "../components/customContent";

export default function Therapy() {
  const [content, setContent] = useState();

  useEffect(() => {
    getPage("therapy").then((result) => {
      setContent(result[0].content);
    });
  }, []);

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Therapy</h1>
      <div className={styles.therapyContent}>
        <CustomContent value={content} />
      </div>
    </>
  );
}
