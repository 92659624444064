"use client";

import { useEffect, useState } from "react";
import styles from "../layout.module.css";
import { getPage } from "../sanity/client";
import CustomContent from "../components/customContent";

export default function Donate() {
  const [content, setContent] = useState();

  useEffect(() => {
    getPage("donate").then((result) => {
      setContent(result[0].content);
    });
  }, []);

  return (
    <>
      <h1>Donate</h1>

      <a
        className={styles.donateButton}
        target="_blank"
        href="https://www.zeffy.com/en-US/fundraising/e57a1913-e2ee-4fd9-bf08-3acc46262a79"
      >
        Donate to Scribble
      </a>

      <CustomContent value={content} />
    </>
  );
}
