export const fetchSanityCollection = async (
  fetchFunction: () => Promise<any[]>,
  setFunction: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const collection = await fetchFunction();
  setFunction(collection);
};

export const fetchSanityItem = async (
  fetchFunction: () => Promise<any>,
  setFunction: React.Dispatch<React.SetStateAction<any>>
) => {
  const item = await fetchFunction();
  setFunction(item);
};
