"use client"; // This is a client component 👈🏽

import { Link } from "react-router-dom";
import styles from "./header.module.css";
import Logo from "./logo";
import Nav from "./nav";

export default function Header() {
  return (
    <div className={styles.header}>
      <Logo />
      <Nav />
      <div className="absolute top-3 right-0 flex-col flex hidden sm:hidden md:flex">
        <Link
          className="mb-2 rounded-[100%] text-center no-underline text-white py-2 px-4 bg-green-500 font-bold"
          to="/donate"
        >
          Donate &rarr;
        </Link>
        <Link
          className="rounded-[100%] text-center no-underline text-white py-2 px-4 bg-blue-500 font-bold"
          to="/get-involved"
        >
          Get Involved &rarr;
        </Link>
      </div>
    </div>
  );
}
