import classNames from "classnames";
import styles from "./nav.module.css";
import { Link } from "react-router-dom";

const links = [
  { href: "/get-involved", title: "Get Involved" },
  { href: "/donate", title: "Donate" },
];

export default function TopNav() {
  const path = "/";
  return (
    <div className={styles.topNav}>
      <div className="justify-end flex">
        {links.map((item, i) => {
          return (
            <Link
              key={item.href + item.title}
              className={classNames("md:hidden", styles.topNavLink, {
                [styles.topNavActive]: item.href === path,
              })}
              to={item.href}
            >
              {item.title}
            </Link>
          );
        })}
        <a target="new" href="https://www.instagram.com/scribble.community/">
          <img
            className={styles.instagramIcon}
            src="/instagram.png"
            alt="Instagram"
          />
        </a>
      </div>
    </div>
  );
}
