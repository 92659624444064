import "./globals.css";
import styles from "./layout.module.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Header from "./components/Header";
import Footer from "./components/footer";
import Classes from "./pages/Classes";
import Groups from "./pages/Groups";
import Events from "./pages/Events";
import Therapy from "./pages/Therapy";
import Contact from "./pages/Contact";
import GetInvolved from "./pages/GetInvolved";
import Donate from "./pages/Donate";
import Group from "./pages/Group";
import Event from "./pages/Event";
import Class from "./pages/Class";
import { useEffect } from "react";
import Venue from "./pages/Venue";

function App() {
  return (
    <>
      <BrowserRouter>
        <main className={styles.main}>
          <div className={styles.centerContent}>
            <Header />
            <div className={styles.pageContent}>
              <Routes>
                <Route path="/" element={<About />} />
                <Route path="/classes" element={<Classes />} />
                <Route path="/class/:slug" element={<Class />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/group/:slug" element={<Group />} />
                <Route path="/events" element={<Events />} />
                <Route path="/event/:slug" element={<Event />} />
                <Route path="/therapy" element={<Therapy />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/get-involved" element={<GetInvolved />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/venue" element={<Venue />} />
              </Routes>
            </div>
          </div>
        </main>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
