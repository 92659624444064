"use client";
import { Link } from "react-router-dom";
import { getGroups, imageUrlFor } from "../sanity/client";
import styles from "./groups.module.css";
import classNames from "classnames";
import { fetchSanityCollection } from "./utils";
import { useEffect, useState } from "react";
import { ScribbleGroup } from "../sanity/sanity.types";

export default function Groups() {
  const [groups, setGroups] = useState<ScribbleGroup[]>([]);

  useEffect(() => {
    fetchSanityCollection(getGroups, setGroups);
  }, []);

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Groups</h1>

      <div className={classNames("flexGridThirds", styles.eventsGrid)}>
        {groups.map((item) => {
          return (
            <div
              className={classNames("col", styles.event)}
              key={item.slug + Math.random().toString()}
            >
              <Link to={"/group/" + item?.slug?.current}>
                <div className={styles.thumb}>
                  <div className={styles.title}>
                    <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                  </div>
                  {item.image && (
                    <img
                      src={imageUrlFor(item.image.asset?._ref ?? "")
                        .width(800)
                        .url()}
                    />
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}
