import { imageUrlFor } from "../sanity/client";
import CustomContent from "./customContent";

export default function SlugPage({ content, columned }: any) {
  if (columned) {
    return (
      <>
        <h1>{content.title}</h1>
        {content.date && (
          <p>
            {new Date(content.date).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "America/Los_Angeles",
            })}
          </p>
        )}
        <div className="flexGridThirds">
          <div className="twoCol">
            {content.overview && <CustomContent value={content.overview} />}
            {content.paypalLink && (
              <a className="pillButton" href={content.paypalLink}>
                {content.paypalButtonText}
              </a>
            )}
            <br />
            {content.secondaryPaypalLink && (
              <a className="pillButton" href={content.secondaryPaypalLink}>
                {content.secondaryPaypalButtonText}
              </a>
            )}
          </div>
          <div className="col">
            {content.image && (
              <img src={imageUrlFor(content.image).width(800).url()} />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <h1>{content.title}</h1>
        {content.date && (
          <p>
            {new Date(content.date).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "America/Los_Angeles",
            })}
          </p>
        )}
        {content.overview && <CustomContent value={content.overview} />}
        {content.image && (
          <img src={imageUrlFor(content.image).width(800).url()} />
        )}
      </>
    );
  }
}
