"use client";

import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { imageUrlFor } from "../sanity/client";

const myPortableTextComponents = {
  types: {
    image: ({ value }: { value: any }) => {
      if (value) {
        const src = imageUrlFor(value).url();
        return <img alt="" src={src} />;
      }
    },
  },
} as Partial<PortableTextReactComponents>;

export default function CustomContent({ value }: any) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (value) {
      setLoading(false);
    }
  }, value);

  return (
    <>
      {loading && (
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      )}
      {!loading && (
        <PortableText components={myPortableTextComponents} value={value} />
      )}
    </>
  );
}
