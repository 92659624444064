import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getHomeImages, getVenueImages, imageUrlFor } from "../sanity/client";
import { HomeImage } from "../sanity/sanity.types";
import { Link } from "react-router-dom";

async function getImages(
  setImages: React.Dispatch<React.SetStateAction<any[]>>
) {
  const images = await getVenueImages();
  setImages(images);
}

export default function Venue() {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    getImages(setImages);
  }, []);

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Venue</h1>

      <Carousel autoPlay showThumbs={false}>
        {images &&
          images?.map((item, i) => (
            <div key={i}>
              {item.image && (
                <img src={imageUrlFor(item.image).width(1000).url()} />
              )}
            </div>
          ))}
      </Carousel>

      <h2 className="font-bold mt-8 mb-4 text-2xl">Shows</h2>
      <p>
        In addition to being a therapy center, Scribble is also a
        fully-equipped, 225-capacity music and arts venue. We host all kinds of
        events, including concerts, comedy nights, film screenings, variety
        shows, and much more.
      </p>
      <p>
        We are a sober space, and every event at Scribble is all-ages. Since we
        don't need to incentivize alcohol sales, we're able to focus on
        cultivating an environment that fosters genuine connection and
        community. People often describe the experience of shows at Scribble as
        something more akin to a “music recital”-- crowds are attentive,
        respectful, and appreciative of the performers.
      </p>
      <p>
        We are a nonprofit community center, and every event we host is made
        possible through the hard work of our volunteers. Because of that, we
        prioritize shows that are organized with an extra level of personal care
        and enthusiasm. We also prioritize uplifting marginalized voices and
        making space for people, sounds, ideas and approaches that are often
        excluded by the music and entertainment industries.
      </p>
      <p>
        If you're interested in setting up a show at Scribble, please fill out
        the form below. Due to the large number of submissions we receive, we
        are only able to host a small fraction of the shows that are proposed to
        us. If you don't hear back from us after submitting, feel free to submit
        again in a month or two.
      </p>
      <div className="bg-scribbleorange inline-block py-3 px-6 font-bold">
        <a
          href="https://forms.gle/Qzytbg5EcrUuogww8"
          className="text-white no-underline"
          target="new"
        >
          Show Inquiry Form
        </a>
      </div>

      <hr className="my-8 border-scribbleorange" />

      <h2 className="font-bold mt-8 mb-4 text-2xl">
        Classes, Workshops and Other Events
      </h2>
      <p>
        Scribble hosts classes, workshops, and other events for the education
        and enjoyment of the community. We put special emphasis on events
        related to arts and mental health, but we are open to anything that
        brings people together. We prioritize classes and workshops that are led
        by and relate to BIPOC/queer/femme communities.
      </p>

      <p>
        If you are interested in setting up a class, workshop, or similar event,
        please fill out the form below. Due to the large number of submissions
        we receive, we are only able to host a small fraction of the events that
        are proposed to us. If you don’t hear back from us after submitting,
        feel free to submit again in a month or two.
      </p>

      <div className="bg-scribbleorange inline-block py-3 px-6 font-bold">
        <a
          href="https://forms.gle/QQRRo6DRJcoCFL6e8"
          className="text-white no-underline"
          target="new"
        >
          Workshop/Class/Event Inquiry Form
        </a>
      </div>

      <hr className="my-8 border-scribbleorange" />

      <h2 className="font-bold mt-8 mb-4 text-2xl">Rentals</h2>

      <p>
        Scribble is available for hourly rentals for parties and other events.
        For more information about renting the venue, please fill out the form
        below.
      </p>

      <div className="bg-scribbleorange inline-block py-3 px-6 font-bold">
        <a
          href="https://forms.gle/kTwwMUqRN2wzUyt86"
          className="text-white no-underline"
          target="new"
        >
          Rental Inquiry Form
        </a>
      </div>

      <div style={{ marginBottom: "100px" }} />
    </>
  );
}
