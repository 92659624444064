import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import {
  HomeImage,
  SanityImageAsset,
  ScribbleClass,
  ScribbleEvent,
  ScribbleGroup,
} from "./sanity.types";

const clientConfig = {
  projectId: "59zw0zbs",
  dataset: "production",
  useCdn: true,
  apiVersion: "2024-07-01",
};

const client = createClient(clientConfig);

export async function getEvents(): Promise<ScribbleEvent[]> {
  return await createClient(clientConfig).fetch(
    // @ts-ignore
    "*[_type == 'scribbleEvent'] | order(date asc)"
  );
}
export async function getClasses(): Promise<ScribbleClass[]> {
  // @ts-ignore
  return await client.fetch("*[_type == 'scribbleClass']");
}
export async function getGroups(): Promise<ScribbleGroup[]> {
  // @ts-ignore
  return await client.fetch("*[_type == 'scribbleGroup']");
}
export async function getBlogPosts() {
  // @ts-ignore
  return await client.fetch("*[_type == 'blogPosts']");
}
export async function getPage(page: string) {
  return await client.fetch(
    // @ts-ignore
    "*[_type == 'page' && slug.current == '" + page + "']"
  );
}
export async function getEvent(slug: string) {
  // @ts-ignore
  return await client.fetch("*[slug.current == '" + slug + "']");
}
export async function getGroup(slug: string) {
  // @ts-ignore
  return await client.fetch("*[slug.current == '" + slug + "']");
}
export async function getHomeImages() {
  // @ts-ignore
  return await client.fetch("*[_type == 'homeImage']");
}
export async function getVenueImages() {
  // @ts-ignore
  return await client.fetch("*[_type == 'venueImage']");
}

// Get a pre-configured url-builder from sanity client
const builder = imageUrlBuilder(createClient(clientConfig));

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
export function imageUrlFor(source: string) {
  return builder.image(source);
}
