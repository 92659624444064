import { getGroup } from "../sanity/client";
import SlugPage from "../components/slugPage";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Group() {
  const { slug } = useParams();
  const [content, setContent] = useState();

  useEffect(() => {
    if (slug) {
      getGroup(slug).then((result) => {
        setContent(result[0]);
      });
    }
  }, []);

  return (
    <>
      <Link to="/groups">&lt; Back to all groups</Link>
      {content && <SlugPage columned content={content} />}
    </>
  );
}
