"use client";

import { Link } from "react-router-dom";
import { getEvents, imageUrlFor } from "../sanity/client";
import styles from "./events.module.css";
import classNames from "classnames";
import { ScribbleEvent } from "../sanity/sanity.types";
import { useEffect, useState } from "react";
import { fetchSanityCollection } from "./utils";

export default function Events() {
  const [events, setEvents] = useState<ScribbleEvent[]>([]);

  useEffect(() => {
    fetchSanityCollection(getEvents, setEvents);
  }, []);

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Upcoming Events</h1>

      <p>
        If you&apos;d be interested in booking your event at Scribble, check out
        our <Link to="/venue">venue page</Link> for more information and links
        to inquiry forms.
      </p>

      <div className={classNames("flexGridThirds", styles.eventsGrid)}>
        {events.map((item) => {
          if (
            item.date &&
            new Date(item.date).getTime() > new Date().getTime()
          ) {
            return (
              <div
                className={classNames("col", styles.event)}
                key={item.slug + Math.random().toString()}
              >
                <p>
                  {new Date(item.date).toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    timeZone: "America/Los_Angeles",
                  })}
                </p>
                <Link to={"/event/" + item?.slug?.current}>
                  <div className={styles.thumb}>
                    <div className={styles.title}>
                      <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                    </div>
                    {item.image && (
                      <img
                        src={imageUrlFor(item.image.asset?._ref ?? "")
                          .width(800)
                          .url()}
                      />
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>

      <hr />

      <h1 className="font-bold text-3xl mb-4">Past Events</h1>

      <div
        className={classNames(
          "flexGridThirds",
          styles.eventsGrid,
          styles.pastEvents
        )}
      >
        {events.reverse().map((item) => {
          if (
            item.date &&
            new Date(item.date).getTime() < new Date().getTime()
          ) {
            return (
              <div
                className={classNames("col", styles.event)}
                key={item.slug + Math.random().toString()}
              >
                <p>
                  {new Date(item.date).toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    timeZone: "America/Los_Angeles",
                  })}
                </p>
                <Link to={"/event/" + item?.slug?.current}>
                  <div className={styles.thumb}>
                    <div className={styles.title}>
                      <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                    </div>
                    {item.image && (
                      <img
                        src={imageUrlFor(item.image.asset?._ref ?? "")
                          .width(800)
                          .url()}
                      />
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
