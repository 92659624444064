import { Link } from "react-router-dom";
import styles from "./logo.module.css";

export default function Logo() {
  return (
    <div className={styles.logo}>
      <Link to="/">
        <img
          className={styles.logoImage}
          src="/scribble-logo.png"
          alt="Scribble Logo"
        />
      </Link>
    </div>
  );
}
