import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getHomeImages, imageUrlFor } from "../sanity/client";
import { HomeImage } from "../sanity/sanity.types";
import { Link } from "react-router-dom";

async function getImages(
  setImages: React.Dispatch<React.SetStateAction<any[]>>
) {
  const images = await getHomeImages();
  setImages(images);
}

export default function About() {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    getImages(setImages);
  }, []);

  return (
    <>
      <div className="border-orange-600 border mb-8 p-8 bg-orange-100 flex justify-between flex-col sm:flex-col lg:flex-row">
        <h2 className="font-bold text-2xl mt-1">
          Donate to help Scribble expand!
        </h2>
        <Link className="no-underline" to="/donate">
          <div className="py-2 px-4 no-underline bg-scribbleorange text-white font-bold">
            More info &rarr;
          </div>
        </Link>
      </div>
      <h2 className="text-2xl font-bold mb-8">
        Scribble is a non-profit counseling center located in Highland Park Los
        Angeles. We offer affordable individual, couples, group, and family
        therapy sessions on a sliding scale. Services are available in person as
        well as through telehealth in the state of California.
      </h2>
      <Carousel autoPlay showThumbs={false}>
        {images &&
          images?.map((item, i) => (
            <div key={i}>
              {item.image && (
                <img src={imageUrlFor(item.image).width(1000).url()} />
              )}
            </div>
          ))}
      </Carousel>
      <div style={{ marginBottom: "100px" }} />
    </>
  );
}
