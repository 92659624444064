import { useEffect, useState } from "react";
import { getClasses, imageUrlFor } from "../sanity/client";
import { PortableText } from "@portabletext/react";
import styles from "./classes.module.css";
import CustomContent from "../components/customContent";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ScribbleClass } from "../sanity/sanity.types";
import { fetchSanityCollection } from "./utils";

export default function Classes() {
  const [classes, setClasses] = useState<ScribbleClass[]>([]);

  useEffect(() => {
    fetchSanityCollection(getClasses, setClasses);
  }, []);

  return (
    <>
      <h1 className="font-bold text-3xl mb-4">Upcoming Classes</h1>

      <div className={classNames("flexGridThirds", styles.classesGrid)}>
        {classes.map((item) => {
          if (
            item.date &&
            new Date(item.date).getTime() > new Date().getTime()
          ) {
            return (
              <div
                className={classNames("col", styles.class)}
                key={item.slug + Math.random().toString()}
              >
                {item.date && (
                  <p>
                    {new Date(item.date).toLocaleDateString("en-us", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "America/Los_Angeles",
                    })}
                  </p>
                )}
                <Link to={"/class/" + item?.slug?.current}>
                  <div className={styles.thumb}>
                    <div className={styles.title}>
                      <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                    </div>
                    {item.image && (
                      <img
                        src={imageUrlFor(item.image.asset?._ref ?? "")
                          .width(800)
                          .url()}
                      />
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>

      <hr className="my-8 border-scribbleorange" />
      <h1 className="font-bold text-3xl mb-4">Ongoing Classes</h1>

      <div className={classNames("flexGridThirds", styles.classesGrid)}>
        {classes.map((item) => {
          if (item.date === undefined) {
            return (
              <div
                className={classNames("col", styles.class)}
                key={item.slug + Math.random().toString()}
              >
                {item.date && (
                  <p>
                    {new Date(item.date).toLocaleDateString("en-us", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "America/Los_Angeles",
                    })}
                  </p>
                )}
                <Link to={"/class/" + item?.slug?.current}>
                  <div className={styles.thumb}>
                    <div className={styles.title}>
                      <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                    </div>
                    {item.image && (
                      <img
                        src={imageUrlFor(item.image.asset?._ref ?? "")
                          .width(800)
                          .url()}
                      />
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>

      <hr className="my-8 border-scribbleorange" />
      <h1 className="font-bold text-3xl mb-4">Past Classes</h1>
      <div className={classNames("flexGridThirds", styles.classesGrid)}>
        {classes.reverse().map((item) => {
          if (
            item.date &&
            new Date(item.date).getTime() < new Date().getTime()
          ) {
            return (
              <div
                className={classNames("col", styles.class)}
                key={item.slug + Math.random().toString()}
              >
                {item.date && (
                  <p>
                    {new Date(item.date).toLocaleDateString("en-us", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "America/Los_Angeles",
                    })}
                  </p>
                )}
                <Link to={"/class/" + item?.slug?.current}>
                  <div className={styles.thumb}>
                    <div className={styles.title}>
                      <h3 className="font-bold mb-4 text-lg">{item.title}</h3>
                    </div>
                    {item.image && (
                      <img
                        src={imageUrlFor(item.image.asset?._ref ?? "")
                          .width(800)
                          .url()}
                      />
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
